<template>
  <div>
    <nav>
      <!-- first app bar in web page by logo and social media -->
      <v-toolbar
        dense
        color="#1f659b"
        class="hidden-md-and-down xz pa-1 justify-space-between"
        max-height="100px"
      >
        <v-toolbar-title>
          <span>
            <router-link to="/">
              <v-img
                src="@/assets/Images/52.png"
                max-height="80px"
                contain
                position="right"
            /></router-link>
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <div class="d-flex social_media_color">
          <v-tooltip bottom v-for="(item, i) in social_media" :key="i">
            <template v-slot:activator="{ on }">
              <a :href="item.link" target="_blank">
                <img :src="item.srcmedia" class="ma-1" width="30vh" v-on="on"
              /></a>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </div>
      </v-toolbar>

      <v-toolbar class="hidden-md-and-down toolbarTabs">
        <v-toolbar-items>
          <div v-for="(item, i) in menuItems" :key="i">
            <v-menu
              v-if="item.subMenuItem"
              open-on-hover
              offset-y
              bottom
              left
              class="xx"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  id="btnTab"
                  class="no-active xx"
                  depressed
                  v-on="on"
                  height="100%"
                >
                  <v-icon v-if="item.icon" class="blue--text">{{
                    item.icon
                  }}</v-icon>
                  <span class="btnTextTab">{{ item.title }}</span>
                  <v-icon class="blue--text" center>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item-group
                  v-model="model"
                  active-class="active_submenu"
                >
                  <div v-for="(oItem, i) in item.subMenuItem" :key="i">
                    <v-list-item
                      class="activeItemSubMenu"
                      no-action
                      :to="oItem.subLinkItem"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-bird</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="oItem.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </v-list-item-group>
              </v-list>
            </v-menu>

            <v-btn
              class="btnMainTab"
              v-else
              depressed
              v-on="on"
              height="100%"
              :to="item.link"
            >
              <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
              <h3>{{ item.title }}</h3>
            </v-btn>
          </div>
        </v-toolbar-items>

        <v-text-field
          solo
          v-model="selectedSearch"
          class="mt-7 mr-5"
          @keypress.enter="searchspecificItem"
          @click:prepend-inner="searchspecificItem"
          label="search"
          prepend-inner-icon="search"
        ></v-text-field>
      </v-toolbar>
      <v-toolbar class="hidden-lg-and-up">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>
          <span>
            <v-img
              src="@/assets/Images/53.png"
              max-height="55px"
              contain
              position="right"
            />
          </span>
        </v-toolbar-title>
      </v-toolbar>
      <!-- list menu in mob -->
      <v-navigation-drawer
        width="90%"
        v-model="drawer"
        absolute
        temporary
        right
        app
        class="hidden-lg-and-up"
      >
        <v-list-item class="itemContent">
          <v-list-item-content>
            <v-list-item-title>
              <router-link to="/">
                <v-img
                  max-height="200px"
                  max-width="200px"
                  src="https://i.ibb.co/hC8tMYk/ascww-logo.png"
                  contain
                  position="center"
                  alt=" شركه مياه الشرب والصرف الصحي بأسيوط والوادي الجديد"
              /></router-link>
              <span>
                <v-text-field
                  v-model="selectedSearch"
                  class="mt-5"
                  solo
                  @keypress.enter="searchspecificItem"
                  label="search"
                  prepend-inner-icon="search"
                ></v-text-field>
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list>
          <v-list-group
            v-for="(item, i) in menuItems"
            :key="i"
            class="itemTitle"
            active-class="highlighted"
          >
            <v-list-item right slot="activator" :to="item.link">
              <v-list-item-title class="white--text pa-1">
                {{ item.title }}</v-list-item-title
              >
            </v-list-item>
            <div v-if="item.subMenuItem">
              <div v-for="(sub, i) in item.subMenuItem" :key="i">
                <v-list-item class="subTitleNavigator" :to="sub.subLinkItem">
                  <v-list-item-title
                    :to="{ name: sub.subLinkItem, params: { sub } }"
                    class="white--text pa-2"
                    >{{ sub.text }}</v-list-item-title
                  >
                  <v-divider></v-divider>
                </v-list-item>
                <v-divider class="white--text"></v-divider>
              </div>
            </div>
          </v-list-group>
        </v-list>

        <div class="d-flex social_media_color_sm">
          <v-tooltip bottom v-for="(item, i) in social_media" :key="i">
            <template v-slot:activator="{ on }">
              <a :href="item.link" target="_blank">
                <img :src="item.srcmedia" class="ma-1" width="30vh" v-on="on"
              /></a>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </div>
      </v-navigation-drawer>
    </nav>
  </div>
</template>
<script>
import axios from "axios";
export default {
  created() {
    this.$store.dispatch("findSelectedItem");
    this.$store.dispatch("findAllProjects");
    this.$store.dispatch("findAllTenders");
    this.$store.dispatch("findSelectedNewsHome");
    this.$store.dispatch("findHomeProjects");
    this.$store.dispatch("findAllCareers");
    this.$store.dispatch("adminInfoFun");
    this.$store.dispatch("adminBoardMemberFun");
  },
  data() {
    return {
      selectedSearch: "",
      search: null,
      drawer: null,
      model: 1,
      items: [],
    };
  },
  methods: {
    async searchspecificItem() {
      if (!this.selectedSearch) {
        return;
      }
      this.$store.commit("setNewArrayfunction", []);
      await axios
        .get(
          "https://backend.ascww.org/api/search/" + this.selectedSearch
          // `https://new.ascww.org/api/selectedItemSearch/` + this.selectedSearch
          // `https://localhost:8080/api/selectedItemSearch/` + this.selectedSearch
        )
        .then((response) => {
          if (
            response.data["navbar_items"].length > 0 ||
            response.data["news"].length > 0 ||
            response.data["projects"].length > 0 ||
            response.data["tenders"].length > 0
          ) {
            this.$store.commit("setNewArrayfunction", response.data);
            // if (this.$router.currentRoute.path != "/show-selected-searchs") {
            this.$router.push("/show-selected-searchs");
          } else {
            this.$router.push("/sorryPage");
          }
        })
        .catch((e) => {
          console.log("Error " + e);
        });
    },
  },

  computed: {
    social_media() {
      return this.$store.state.social_media;
    },
    menuItems() {
      return this.$store.state.menuItems;
    },
  },
};
</script>
<style>
.social_media_color a:hover {
  opacity: 0.8;
}
.itemContent,
.itemTitle {
  background: linear-gradient(to left, #003369, #578cb4);
  font-family: fantasy, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.subTitleNavigator {
  background: #00295f;
  letter-spacing: normal;
}
.highlighted {
  background-color: #578cb4;
}
.mainToolbar {
  background: #578cb4;
}

.subTitle {
  position: center;
  color: white;
}
.iconColor {
  color: #00295f;
}
.no-active:hover {
  background-color: #1f659b !important;
  color: white !important;
}
.active-list-item:hover {
  background-color: #578cb4;
}

.nestedList {
  border-top-color: rgb(22, 19, 26);
}

.btnTextTab,
.btnMainTab {
  font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif !important;
  letter-spacing: normal !important;
  font-weight: bold;
  font-size: 17px !important;
}
@media screen and (max-width: 1500px) {
  .btnTextTab,
  .btnMainTab {
    font-size: 13px !important;
    font-family: "Source Sans Pro", "Helvetica Neue", Arial, sans-serif !important;
    letter-spacing: normal !important;
    font-weight: bold;
  }
}

.btnMainTab {
  text-transform: unset !important;
}
.bg-active {
  background-color: black;
  color: white !important;
}
.active_submenu {
  background: linear-gradient(to left, #003369, #578cb4);
  color: white !important;
}

.activeItemSubMenu:hover {
  background: linear-gradient(to left, #0033699c, #578cb488);
  color: white !important;
}

.v-text-field input {
  color: #1f659b !important;
}
.social_media_color_sm {
  margin-right: 150px;
}
.yy {
  text-transform: unset !important;
}
.xx {
  padding: 6px !important;
}
@media screen and (max-width: 1500px) {
  .xx {
    padding: 0px !important;
  }
}
.yy {
  padding: 0 !important;
  font-size: 10px;
}
</style>
