import { render, staticRenderFns } from "./CopyRight.vue?vue&type=template&id=30b8a3c2&scoped=true&"
var script = {}
import style0 from "./CopyRight.vue?vue&type=style&index=0&id=30b8a3c2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30b8a3c2",
  null
  
)

export default component.exports