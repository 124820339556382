<template>
  <v-footer class="footer" id="footer" dir="ltr">
    <div class="container mt-10 mb-10">
      <!-- <v-row>
        <div id="app">
          <p>{{ count }}</p>
          <p>
            <button @click="increment">+</button>
            <button @click="decrement">-</button>
          </p>
          <small> Check out your cookies to see the updates. </small>
        </div>
      </v-row> -->
      <v-row>
        <div dir="rtl" class="col-sm-12 col-md-3 footerText">
          <h2>مقـــر الشـــركــة</h2>
          <a
            target="_blank"
            href="https://www.google.com/maps/place/Company+water+and+sanitation+in+Assiut/@27.1757526,31.2029076,15.25z/data=!4m5!3m4!1s0x14450b972d0fb135:0xcfbbd00ecfc7e476!8m2!3d27.1729526!4d31.2030724"
          >
            <img src="@/assets/Images/m_map.png" width="350px" class="mt-2" />
          </a>
        </div>
        <div dir="rtl" class="col-sm-12 col-md-3 footerText">
          <h2>بيــانـات الشـــركــة</h2>
          <p class="mt-5">
            عنوان الفرع الرئيسى: محطه مياه المرشحه بنزله عباللاه- اسيوط
          </p>
          <p>رقم الهاتف: 2331604</p>
          <p>صندوق بريد: 71111</p>
          <p>رقم الفاكس: 088-2131662</p>
          <div class="d-flex social_media_color">
            <v-tooltip bottom v-for="(item, i) in social_media" :key="i">
              <template v-slot:activator="{ on }">
                <a :href="item.link" target="_blank">
                  <img :src="item.srcmedia" class="ma-1" width="30vh" v-on="on"
                /></a>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
        </div>
        <div dir="rtl" class="col-sm-12 col-md-3 footerText">
          <v-img src='require("@/assets/Images/boss.jpg")' />
          <p class="mt-5">
            شركة مياه الشرب والصرف الصحي بأسيوط و الوادي الجديد أنشأت عام 1981
            كشركة قطاع عام بغرض إدارة وتشغيل وتطوير مرافق المياه بدوائر المحافظة
            المختلفة. بصدور القرار الجمهوري رقم 135 لسنة 2004 أصبحت الشركة احدي
            الشركات التابعة للشركة القابضة لمياه الشرب والصرف الصحي.
          </p>
          <div>
            <router-link
              to=""
              class="removeTextDecoration"
              @click="phoneNumFun"
            >
              <v-icon class="iconContact" color="white" @click="phoneNumFun"
                >phone</v-icon
              >
            </router-link>
            <router-link to="" class="removeTextDecoration">
              <v-icon class="iconContact" color="white" @click="emailFun"
                >mail</v-icon
              >
            </router-link>
          </div>
        </div>
        <div dir="rtl" class="col-sm-12 col-md-3 footerText">
          <h5>
            مقـــر المدرسة الفنيه لمياه الشرب والصرف الصحي بأسيوط والوادي الجديد
            -أسيوط الجديدة
          </h5>
          <a
            target="_blank"
            href="https://www.google.com/maps/place/%D9%85%D8%AF%D8%B1%D8%B3%D8%A9+%D9%85%D9%8A%D8%A7%D9%87+%D8%A7%D9%84%D8%B4%D8%B1%D8%A8+%D9%88%D8%A7%D9%84%D8%B5%D8%B1%D9%81+%D8%A7%D9%84%D8%B5%D8%AD%D9%89%E2%80%AD/@27.2859527,31.2822879,17z/data=!3m1!4b1!4m5!3m4!1s0x14456dca55505137:0x9fe6d219ff1c8541!8m2!3d27.2859527!4d31.2844766"
          >
            <img src="@/assets/Images/school.png" width="250px" class="mt-2" />
          </a>
        </div>
      </v-row>
    </div>
    <v-snackbar v-model="snackbar" :vertical="vertical" dir="rtl">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          غلق
        </v-btn>
      </template>
    </v-snackbar>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      snackbar: false,
      text: "",
      phoneNum: "01280733990",
      textEmail: "media-water@ascww.com.eg",
    };
  },
  components: {},
  methods: {
    phoneNumFun() {
      this.snackbar = true;
      this.text =
        "تم نسخ رقم تليفون الشركه وهو(  01280733990 )أذهب للصق في أي مكان";
      navigator.clipboard.writeText(this.phoneNum);
    },
    emailFun() {
      this.snackbar = true;
      this.text =
        " تم نسخ الميل الرسمي للشركه مياه الشرب والصرف الصحي بأسيوط (media-water@ascww.com.eg) أذهب للصق في أي مكان";
      navigator.clipboard.writeText(this.textEmail);
    },
  },
  computed: {
    social_media() {
      return this.$store.state.social_media;
    },
  },
};
</script>
<style scoped>
#footer {
  background: linear-gradient(rgb(66, 65, 65), rgb(41, 39, 39)) !important;
  bottom: 0px;
  color: white;
}

.footerText {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.copyRight {
  background: yellow;
}

.social_media_color a:hover {
  opacity: 0.5;
}

.iconContact {
  margin-left: 20px !important;
  color: white;
}

.iconContact:hover {
  opacity: 0.5;
}

.removeTextDecoration {
  text-decoration: none !important;
}
</style>
