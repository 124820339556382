<template>
  <div
    id="Home"
    :style="{
      backgroundImage:
        'url(' + require('@/assets/Images/mainbackground.jpg') + ')',
    }"
  >
    <v-carousel
      cycle
      width="100%"
      height="30em"
      hide-delimiter-background
      show-arrows-on-hover
    >
      <template v-slot:prev="{ on, attrs }">
        <v-btn color="gray" v-bind="attrs" v-on="on" class="hidden-sm-and-down"
          ><v-icon center>mdi-arrow-left-box</v-icon></v-btn
        >
        <v-btn color="gray" v-bind="attrs" v-on="on" class="hidden-md-and-up"
          ><v-icon center>mdi-arrow-left-box</v-icon></v-btn
        >
      </template>
      <template v-slot:next="{ on, attrs }">
        <v-btn color="gray" v-bind="attrs" v-on="on" class="hidden-sm-and-down"
          ><v-icon center>mdi-arrow-right-box</v-icon>
        </v-btn>
        <v-btn color="gray" v-bind="attrs" v-on="on" class="hidden-md-and-up"
          ><v-icon center>mdi-arrow-right-box</v-icon></v-btn
        >
      </template>

      <div v-for="(item, i) in itemsX" :key="i" class="fade-in">
        <v-carousel-item
          class="fade-in white--text"
          v-bind:style="{
            'background-image': 'url(' + item.src + ') ',
            'background-size': '100% 100%',
          }"
        >
          <v-sheet
            height="100%"
            width="100%"
            class="sheet-carousel"
            color="rgb(0, 0, 0, 0.6)"
          >
            <v-container
              position="center"
              class="fill-height"
              align="center"
              justify="center"
            >
              <v-layout column justify-center align-center>
                <v-flex xs12 sm8 md6>
                  <div>
                    <div class="bounce-in-top">
                      <h2 align="center" class="caroselItem text-center">
                        {{ item.title }}
                      </h2>
                      <h5 align="center" width="30px">{{ item.subTitle }}</h5>
                    </div>
                    <h5 align="center">
                      <v-btn
                        v-if="item.details"
                        :to="item.link"
                        depressed
                        v-bind:style="{
                          'margin-top': '20px',
                          'padding-top': '10px',
                          background: '#1f659b',
                          color: 'white',
                        }"
                      >
                        <p>
                          {{ item.details }}
                          <v-icon center>mdi-chevron-down</v-icon>
                        </p>
                      </v-btn>
                    </h5>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </v-sheet>
        </v-carousel-item>
      </div>
    </v-carousel>

    <v-container class="lastestNews">
      <div class="newsClass">
        <v-row>
          <v-col cols="1"
            ><v-btn
              depressed
              class="white--text"
              color="#1f659b"
              @click="changedata"
              >أهم الأخبار</v-btn
            >
          </v-col>

          <v-col cols="10">
            <v-carousel
              height="50"
              cycle
              :show-arrows="false"
              hide-delimiters
              hide-delimiter-background
            >
              <v-carousel-item v-for="(itemMarqee, i) in homeNews" :key="i">
                <span>
                  <router-link
                    :to="{
                      name: 'News',
                      params: { userId: itemMarqee.slug },
                    }"
                    class="parMarquee"
                  >
                    {{ itemMarqee.title }}
                  </router-link>
                </span>
              </v-carousel-item>
            </v-carousel>
          </v-col>

          <v-col cols="1"> </v-col>
          <v-row justify="center"
            ><v-btn
              depressed
              class="white--text"
              color="#1f659b"
              @click="newsFunc"
              >لمزيد من المتابعة لاخبار الشركة يرجى زيارة ارشيف الاخبار
            </v-btn></v-row
          >
        </v-row>
      </div>
    </v-container>

    <v-divider class="mr-10" width="90%" align="center"></v-divider>
    <div class="container pa-10">
      <v-row>
        <v-col cols="12" md="6" lg="6" xs="12"
          ><v-img
            :src="'https://backend.ascww.org/api/image/boss.jpg'"
            class="photoBoss"
            :lazy-src="loadingLink"
          ></v-img
        ></v-col>
        <v-col class="center">
          <h3 v-animate-onscroll="'fade-in'" class="text-center indigo--text">
            كلمة السيد رئيس مجلس الإداره والعضو المنتدب
          </h3>

          
          <p class="mt-2 ql-editor" v-html="adminInfo.boss_speech"></p>
          

          <h4 class="text-center">
            {{ adminInfo.boss_title }}
            <br />
            {{ adminInfo.boss_name }}
          </h4>
        </v-col>
      </v-row>
    </div>
    <v-divider class="mr-10" width="90%" align="center"></v-divider>
    <div id="services">
      <h1
        align="center"
        class="changeUnderLine"
        v-animate-onscroll="'rotate-vert-center'"
      >
        <a href="" class="mb-10">خـدمــاتـنـا</a>
      </h1>
      <v-container>
        <v-row justify="center" ma-3>
          <div
            class="col-xs-4 col-sm-3 col-md-3 col-lg-3 justify-center container"
            v-for="(itemService, i) in cardServices"
            :key="i"
          >
            <v-row justify="center">
              <router-link :to="itemService.link">
                <v-card
                  class="cardServiceClass xx"
                  v-bind:style="{
                    'background-image': 'url(' + itemService.img + ') ',
                    'background-size': '100% 100%',
                    'box-shadow': 'gray 2px 2px 2px 2px',
                    'max-width': '15em',
                  }"
                >
                </v-card>
                <v-row class="justify-center textBoss"
                  ><h4>{{ itemService.item }}</h4></v-row
                >
                <v-row class="subTextService text-center pa-5" max-width="15em"
                  ><h5>{{ itemService.subText }}</h5></v-row
                >
              </router-link>
            </v-row>
          </div>
        </v-row>
      </v-container>
    </div>
    <div class="mb-5">
      <h1
        align="center"
        class="changeUnderLine"
        v-animate-onscroll="'rotate-vert-center'"
      >
        <a href="" class="mb-10">مشـروعاتنا </a>
      </h1>
      <v-row
        no-gutters
        class="justify-center"
        v-if="checkHomeProjects.length >= 1"
      >
        <v-col
          v-for="(ItemAction, i) in checkHomeProjects"
          :key="i"
          cols="12"
          sm="2"
          v-show="i < 4"
          class="ma-3"
        >
          <v-carousel
            :show-arrows="false"
            width="100%"
            height="15em"
            hide-delimiters
            class="shadow"
          >
            <div
              class="fade-in"
              v-for="(locationImg, index) in ItemAction.project_images"
              v-bind:selected="index === 0"
              :key="index"
            >
              <v-carousel-item
                class="fade-in white--text"
                v-bind:style="{
                  'background-image':
                    'url(https://backend.ascww.org/api/projects/image/' +
                    locationImg.path +
                    ') ',
                  'background-size': '100% 100%',
                }"
              >
                <v-sheet
                  height="100%"
                  width="100%"
                  class="sheet-carousel"
                  color="rgb(0, 0, 0, 0.4)"
                >
                  <v-container
                    position="center"
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <v-layout column justify-center align-center>
                      <v-flex xs12 sm8 md6>
                        <div>
                          <div :class="ItemAction.animeClass">
                            <h4
                              align="center"
                              class="caroselItem"
                              v-if="ItemAction.title"
                            >
                              {{ ItemAction.title }}
                            </h4>
                          </div>
                          <h6 align="center">
                            <v-btn
                              v-if="ItemAction.description"
                              :to="{
                                name: 'ShowMore',
                                params: { userId: ItemAction.slug },
                              }"
                              depressed
                              v-bind:style="{
                                'margin-top': '20px',
                                background: '#1f659b',
                                color: 'white',
                              }"
                            >
                              المزيد
                              <v-icon center>mdi-chevron-down</v-icon>
                            </v-btn>
                          </h6>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-sheet>
              </v-carousel-item>
            </div>
          </v-carousel>
          <!--   <h5 class="ma-2 text--center" >{{ ItemAction.item }}</h5> -->
        </v-col>
      </v-row>
      <v-row v-else
        ><v-img
          :src="loadingLink"
          width="200px"
          height="200px"
          contain
          class="loadImgClass"
        ></v-img
      ></v-row>
    </div>
    <div id="OurNumber">
      <v-container>
        <v-row class="justify-space-around container">
          <v-col
            v-for="item in ourNumbersArr"
            :key="item.key"
            cols="12"
            sm="6"
            lg="2"
          >
            <v-row justify="center"
              ><v-icon
                color="#578cb4"
                class="iconNumber"
                v-animate-onscroll="'rotate-vert-center'"
                >{{ item.iconName }}</v-icon
              ></v-row
            >
            <v-row justify="center"
              ><p class="numberSelected" v-animate-onscroll.repeat="'fade-in'">
                {{ item.numberSelected }}
              </p></v-row
            >
            <v-row justify="center" class="nameSelected">{{
              item.nameSelected
            }}</v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-divider class="mr-10" width="90%" align="center"></v-divider>

    <v-divider class="mr-10" width="90%" align="center"></v-divider>
    <div>
      <h1
        align="center"
        class="changeUnderLine"
        v-animate-onscroll="'rotate-vert-center'"
      >
        <a href="" class="mb-10">مواقع تهمك </a>
      </h1>
      <v-row no-gutters class="justify-center">
        <v-col
          v-for="(ItemLink, i) in rightNews"
          :key="i"
          cols="12"
          sm="2"
          class="ma-3"
        >
          <v-carousel
            :show-arrows="false"
            height="15em"
            hide-delimiters
            class="shadow"
            v-bind:style="{
              'border-radius': '20px',
            }"
          >
            <div class="fade-in">
              <v-carousel-item
                class="fade-in white--text"
                v-bind:style="{
                  'background-image': 'url(' + ItemLink.img + ') ',
                  'background-size': '100% 100%',
                }"
                :to="ItemLink.link"
              >
              </v-carousel-item>
            </div>
          </v-carousel>
          <h4 class="ma-2 text-center indigo--text">{{ ItemLink.title }}</h4>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  components: {},

  name: "Home",
  data() {
    return {
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
      checkLang: true,
      loading: false,
      items: [],
      msgLink: require("@/assets/Images/boss.jpg"),
      loadingLink: require("@/assets/Images/load.svg"),
      rightNews: [
        {
          title: "المدرسة الفنيه التابعه لمياه الشرب ",
          link: "/school-new-assuit",
          img: require("@/assets/Images/school_dep/97.jpg"),
        },
        {
          title: "المناقصات",
          link: "/allTenders",
          img: require("@/assets/Images/55.jpg"),
        },
        {
          title: "خـدمـة العـمـلاء",
          link: "/call-center",
          img: require("@/assets/Images/cutomer_service.jpg"),
        },

        {
          title: " الإدارة العامه للتدريب",
          link: "/general-admin-training",
          img: require("@/assets/Images/39.jpg"),
        },
      ],
      ourNumbersArr: [
        {
          iconName: "mdi-account-child-outline",
          numberSelected: "8338",
          nameSelected: "عامل",
        },
        { iconName: "mdi-gitlab", numberSelected: "5", nameSelected: "معمل" },
        {
          iconName: "mdi-account-group",
          numberSelected: "752981",
          nameSelected: "مشترك",
        },
        {
          iconName: "mdi-city-variant-outline",
          numberSelected: "278",
          nameSelected: " محطات الشرب ",
        },
        {
          iconName: "mdi-source-branch",
          numberSelected: "57",
          nameSelected: " محطات الصرف  ",
        },
      ],
    };
  },

  computed: {
    homeNews() {
      var arr = this.$store.state.homeNewsArray;
      return arr.reverse();
    },
    arrayNews() {
      var arr = this.$store.state.newsArrayStore;
      return arr.reverse();
    },
    itemsX() {
      return this.$store.state.items;
    },
    arrayMsg() {
      return this.$store.state.arrayMsg;
    },
    cardServices() {
      return this.$store.state.cardServices;
    },
    checkProject() {
      var arr = this.$store.state.projects;
      return arr.reverse();
    },
    checkHomeProjects() {
      var arr = this.$store.state.homeProjectsArray;
      return arr.reverse();
    },
    adminInfo() {
      return this.$store.state.adminInfo;
    },
  },
  methods: {
    newsFunc() {
      this.$router.push("/news-company");
    },
  },
};
</script>
<style lang="scss" scoped>
#Home {
  background-image: url("https://i.ibb.co/fYK2fZq/bback.webp");
  background-size: cover;
}
.sheet-carousel {
  color: white !important;
}
.newsClass button {
  border-radius: 25px;
  height: 10em;
}
.parMarquee {
  height: 30px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  text-decoration: none;
  color: black;
  font-style: bold;
}
.marquee,
newsMarquee,
span {
  margin-top: 1px;
  display: block;
  background: rgba(61, 64, 66, 0.185);
  border-radius: 25px;
  padding: 5px;
  box-shadow: #1f659b 2px 2px;
}
.rightClassNews img {
  width: 16em;
  box-shadow: white 2px;
  border-radius: 25px;
}
.photoBoss {
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 15px !important;
  box-shadow: 2px 2px 2px 2px gray;
}
.textBoss {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  color: #1f659b;
}
#cardBoss {
  border-radius: 25px;
  background: rgba(5, 20, 236, 0.062);
}
#services {
  width: 100%;
  height: 100%;
  padding: 2em;
  background: white;
}
.cardServiceClass {
  height: 200px;
}
.serviceTitlecard {
  font-weight: bold;
}
a {
  text-decoration: none;
}
a {
  background: linear-gradient(
      to right,
      rgba(100, 200, 200, 0.151),
      rgba(100, 200, 200, 0.39)
    ),
    linear-gradient(
      to right,
      rgba(255, 0, 0, 1),
      rgba(255, 0, 180, 1),
      rgba(0, 100, 200, 1)
    );
  background-size: 100% 0.1em, 0 0.1em;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 400ms;
}
a:hover,
a:focus {
  background-size: 0 0.1em, 100% 0.1em;
}
.newsImg {
  border-radius: 25px;
  box-shadow: gray 2px 2px 2px 2px;
}
.cardColor {
  background: rgba(255, 255, 255, 0) !important;
}
.objF {
  object-fit: contain;
}
.xx {
  margin-left: 17%;
  margin-right: 17%;
  margin-top: 5%;
  margin-bottom: 5%;
}
#OurNumber {
  background: white !important;
}
.iconNumber {
  font-size: 8em;
}
.numberSelected {
  font-size: 3em;
  color: rgb(95, 90, 90);
  font-family: Arial, Helvetica, sans-serif;
}
.nameSelected {
  font-weight: bold;
  font-size: 1.2em;
  color: brown;
}
.colLeft {
  max-width: 250px;
  font-weight: bold;
}
.shadow {
  box-shadow: 2px 2px 2px 2px gray;
}
.changeUnderLine {
  font-size: 1.7em !important;
}
.loadImgClass {
  background-size: cover;
}
</style>
