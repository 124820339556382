import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
Vue.config.silent = true;
const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/show-selected-searchs",
    name: "showSelectedSearch",
    component: () => import("../views/showSelectedSearchs.vue"),
  },
  {
    path: "/sorryPage",
    name: "SorryPage",
    component: () => import("../views/sorryPage.vue"),
  },
  {
    path: "/show-selected-news/:userId",
    name: "News",
    component: () => import("../views/ShowSelectedNews.vue"),
  },
  /* عن الشركه  */
  {
    path: "/an-elsherka",
    name: "aboutThecompany",
    component: () => import("../views/ToolbarTabs/AnElsherka.vue"),
    props: true,
  },
  {
    path: "/birth-of-company",
    name: "birthOfCompany",
    component: () => import("../views/ToolbarTabs/birthOfCompany.vue"),
  },
  {
    path: "/branch-of-company",
    name: "BranchOfCompany",
    component: () => import("../views/ToolbarTabs/branchOfCompany.vue"),
  },
  {
    path: "/projects-company",
    name: "projectsCompany",
    component: () => import("../views/ToolbarTabs/projectsOfCompany.vue"),
  },
  {
    path: "/news-company",
    name: "newsCompany",
    component: () => import("../views/ToolbarTabs/newsOfCompany.vue"),
  },
  {
    path: "/vision-and-message",
    name: "visionAndMessage",
    component: () => import("../views/ToolbarTabs/VisionAndMessage.vue"),
  },
  {
    path: "/organization-structure",
    name: "organizationStructure",
    component: () => import("../views/ToolbarTabs/OrganizationStructure.vue"),
  },
  {
    path: "/company-achivement",
    name: "companyAchivement",
    component: () => import("../views/ToolbarTabs/companyAchivement.vue"),
  },
  {
    path: "/contract-and-sell",
    name: "contractAndSell",
    component: () => import("../views/ToolbarTabs/contractAndSell.vue"),
  },
  {
    path: "/adviceAndContact",
    name: "adviceAndContact",
    component: () => import("../views/AdviceAndservice/adviceAndContact.vue"),
  },
  /*  التوعيه */
  {
    path: "/forKids",
    name: "forKids",
    component: () => import("../views/AdviceAndservice/forKids.vue"),
  },
  {
    path: "/toWomen",
    name: "toWomen",
    component: () => import("../views/AdviceAndservice/toWomen"),
  },
  /*  جوده المياه  */
  {
    path: "/water-quality",
    name: "waterQuality",
    component: () => import("../views/QualityOfWater/waterQuality.vue"),
  },
  {
    path: "/refining-water",
    name: "refiningWater",
    component: () => import("../views/QualityOfWater/refiningWater.vue"),
  },
  {
    path: "/lab-of-company-water",
    name: "lab-of-company-water",
    component: () => import("../views/QualityOfWater/labOfCompanyWater.vue"),
  },
  /* المناقصات */

  /*   صرف صحي  */
  {
    path: "/Riddence-waste-water",
    name: "riddenceWasteWater",
    component: () => import("../views/WasteWater/RiddenceWasteWater.vue"),
  },
  {
    path: "/sewage-treatment",
    name: "SewageTreatment",
    component: () => import("../views/WasteWater/SewageTreatment.vue"),
  },

  {
    path: "/kind-of-waste-water",
    name: "KindOfWasteWater",
    component: () => import("../views/WasteWater/KindStationOfWasteWater.vue"),
  },
  {
    path: "/waste-water-in-manufactring",
    name: "WasteWaterInManufactring",
    component: () => import("../views/WasteWater/manufactring.vue"),
  },
  {
    path: "/save-web-waste-water",
    name: "SaveWebWasteWater",
    component: () => import("../views/WasteWater/SaveWebWasteWater.vue"),
  },
  {
    path: "/manufactring-waste",
    name: "ManufactringWasteWater",
    component: () => import("../views/WasteWater/manufactringWaste.vue"),
  },
  /* وظائف*/
  {
    path: "/jobs-and-competition",
    name: "jobsAndCompitaion",
    component: () => import("../views/Jobs/jobsAndCompitaion.vue"),
  },
  /*{
    path: "/result-of-competition",
    name: "resultOfCompitaion",
    component: () => import("../views/Jobs/resultOfCompitaion.vue"),
  },*/

  {
    path: "/result_of_worker",
    name: "resultOfWorker",
    component: () => import("../views/Jobs/resultOfWorker.vue"),
  },
  /*{
    path: "/date_of_worker_exam",
    name: "dateOfWorkerExam",
    component: () => import("../views/Jobs/ExamOfWorker.vue"),
  },*/
  /*  خدمه العملاء */
  {
    path: "/call-center",
    component: () => import("../views/OurServices/callCenter.vue"),
    /*name: 'callCenter',
    component: () => import('../views/OurServices/callCenter.vue')*/
  },
  {
    path: "/Customer-Charter",
    component: () => import("../views/OurServices/CustomerCharter.vue"),
    /*name: 'callCenter',
    component: () => import('../views/OurServices/callCenter.vue')*/
  },
  {
    path: "/Services-Evidance",
    component: () => import("../views/OurServices/ServicesEvidance.vue"),
    /*name: 'callCenter',
    component: () => import('../views/OurServices/callCenter.vue')*/
  },
  {
    path: "/Contract-On-Service",
    component: () => import("../views/OurServices/ContractOnService.vue"),
    /*name: 'callCenter',
    component: () => import('../views/OurServices/callCenter.vue')*/
  },
  {
    path: "/inquire-your-bill",
    name: "InquireYourBill",
    beforeEnter() {
      // location.href = 'http://197.51.14.144:2030/'
      //   window.open("http://197.51.14.144:2030/inqeury.aspx?pid=inqu",  '_blank');
      window.open("http://bills.ascww.com.eg/Start.aspx", "_blank");
    },
    //    component: () => import('../views/OurServices/inquireYourBill.vue')
  },
  {
    path: "/send-your-reader",
    beforeEnter() {
      window.open("http://bills.ascww.com.eg/Start.aspx", "_blank");
      // window.open("http://197.51.14.144:2030/", '_blank');
      //  location.href = 'http://197.51.14.144:2030/'
    },
    /*name: 'sendYourReader',
    component: () => import('../views/OurServices/sendYourReader.vue')*/
  },
  {
    path: "/provide-complaine",
    name: "ProvideComplaine",
    component: () => import("../views/OurServices/complaine.vue"),
  },
  {
    path: "/provide-request",
    name: "ProvideRequest",
    component: () => import("../views/OurServices/provideRequest.vue"),
  },
  {
    path: "/allTenders",
    name: "AllTenders",
    component: () => import("../views/OurServices/allTenders.vue"),
  },
  {
    path: "/selected-Tender/:userId",
    name: "selectedTender",
    component: () => import("../views/selectedTenders.vue"),
  },
  /* معرض الصور */
  {
    path: "/trips-the-boss",
    name: "tripsTheBoss",
    component: () => import("../views/Gallary/tripsOfTheBoss.vue"),
  },
  {
    path: "/lab-of-company",
    name: "labOfCompany",
    component: () => import("../views/Gallary/labOfCompany.vue"),
  },
  {
    path: "/traning-of-company",
    name: "traningOFCompany",
    component: () => import("../views/Gallary/traningOfCompany.vue"),
  },
  {
    path: "/waste-of-company",
    name: "WasteOfComapny",
    component: () => import("../views/Gallary/wasteOfCompany.vue"),
  },
  {
    path: "/information-technology-of-company",
    name: "informationAndTechnologyOfCompany",
    component: () =>
      import("../views/Gallary/informationTechnologyCompany.vue"),
  },
  {
    path: "/school-new-assuit",
    name: "schoolNewAssuit",
    component: () => import("../views/Gallary/schoolGalary.vue"),
  },
  {
    path: "/hotline125",
    name: "hotline",
    component: () => import("../views/CarauselLinks/Hotline.vue"),
  },
  {
    path: "/sport-of-company",
    name: "sportOfCompany",
    component: () => import("../views/Gallary/sportOFComany.vue"),
  },
  ///////////////////////////elnazaha support
  {
    path: "/nabza-an-daam-elnazaha",
    name: "nabzadaamElnazaha",
    component: () => import("../views/elnzahaSupport/description.vue"),
  },
  {
    path: "/abrz-amaal-daam-elnazaha",
    name: "aamaldaamElnazaha",
    component: () => import("../views/elnzahaSupport/elnazahaWork.vue"),
  },
  {
    path: "/elslookElwazefy",
    name: "elslookElwazefy",
    component: () => import("../views/elnzahaSupport/elslookElwazefy.vue"),
  },
  /////////////////////////////
  {
    path: "/people_assistant",
    name: "PeopleAssistant",
    component: () => import("../views/OpenNewProject/people_assistant.vue"),
  },
  //////////////traning///////////
  {
    path: "/TraningManagement/:userId",
    name: "TraningMangment",
    component: () =>
      import("../views/OpenNewProject/subTraning/TraningManagement.vue"),
  },

  {
    path: "/readme",
    name: "readme",
    component: () => import("../views/CarauselLinks/readMeApp.vue"),
  },
  {
    path: "/openSchoolInNewAssuit",
    beforeEnter() {
      location.href =
        "https://www.facebook.com/Damanhour-Water-School-152425671487980/";
    },
  },
  {
    path: "/general-admin-training",
    name: "GeneralAdminTraning",
    component: () => import("../views/OpenNewProject/TrainingAdmin.vue"),
  },
  // {
  //   path: "/register-in-school",
  //   name: "RegisterInSchool",
  //   component: () => import("../views/OpenNewProject/RegisterInSchool.vue"),
  // },
   {
     path: "/Result-of-school",
     name: "ResultOfSchool",
     component: () => import("../views/OpenNewProject/ResultOfSchool.vue"),
   },
  {
    path: "/Traning-management/:userId",
    name: "TraningManagement",
    component: () =>
      import("../views/OpenNewProject/subTraning/TraningManagement.vue"),
  },
  {
    path: "/under-build",
    name: "UnderBuild",
    component: () => import("../views/UnderBuild.vue"),
  },
  {
    path: "/contract-and-Production",
    name: "ContractAndProduction",
    component: () => import("../views/CarauselLinks/contractAndProduction.vue"),
  },

  {
    path: "/pay-your-bill",
    name: "PayYourBill",
    component: () => import("../views/OurServices/PayYourBill.vue"),
  },
  {
    path: "/show-more/:userId",
    name: "ShowMore",
    component: () => import("../views/showMore"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login/login"),
  },
  {
    path: "/CreateUser",
    name: "CreateUser",
    component: () => import("../views/Login/CreateUser"),
  },
  {
    path: "/openfacebook",
    beforeEnter() {
      location.href = "https://ar-ar.facebook.com/ASCWWeg/";
    },
  },

  {
    path: "*",
    redirect: { name: "home" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
