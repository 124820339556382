import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
//import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    //   count: 0,
    overlay: true,
    projects: [],
    careers: [],
    ca: [],
    pcr: [],
    ger: [],
    wed: [],
    wtr: [],
    ped: [],
    ptr: [],
    idcar: [],
    an: [],
    lang: true,
    menuItems: [
      {
        title: "الرئيسية",
        icon: "home",
        link: "/",
      },
      {
        title: "عن الشركة",
        active: true,
        subMenuItem: [
          { text: "نبذة عن الشركة", subLinkItem: "/an-elsherka" },
          { text: "قرار إنشاء الشركة", subLinkItem: "/birth-of-company" },
          { text: "فروع الشركه", subLinkItem: "/branch-of-company" },
          { text: "مشروعات الشركة", subLinkItem: "/projects-company" },
          { text: "أرشيف الأخبار", subLinkItem: "/news-company" },
          { text: "الرؤيه والرساله", subLinkItem: "/vision-and-message" },
          { text: "الهيكل التنظيمي", subLinkItem: "/organization-structure" },
          { text: "إنجازات الشركة", subLinkItem: "/company-achivement" },
          //  { text: 'دليل الخدمات الجماهيريه', subLinkItem: "/people_assistant" },
          {
            text: "اللائحه الموحده للعقود والمشتريات",
            subLinkItem: "/contract-and-sell",
          },
        ],
      },
      {
        title: "التوعية والاتصال ",
        active: true,
        subMenuItem: [
          { text: "التوعية والأتصال", subLinkItem: "/adviceAndContact" },
          { text: "ركن الأطفال", subLinkItem: "/forKids" },
          { text: "لك سيدتي", subLinkItem: "/toWomen" },
        ],
      },
      {
        title: "جودة المياه",
        active: false,

        subMenuItem: [
          { text: "جودة المياه", subLinkItem: "/water-quality" },
          { text: "تنقية مياه الشرب", subLinkItem: "/refining-water" },
          {
            text: " المعمل المركزي لمياه الشرب",
            subLinkItem: "/lab-of-company-water",
          },
        ],
      },
      {
        title: "المناقصات",
        active: true,

        subMenuItem: [
          {
            text: "جميع المناقصات الموجوده بالشركة",
            subLinkItem: "/allTenders",
          },
        ],
      },
      {
        title: "الصرف الصحي",
        active: true,
        subMenuItem: [
          { text: "معالجه الصرف الصحي", subLinkItem: "/sewage-treatment" },
          {
            text: "تعريف بأهميه التخلص الاَمن من الصرف الصحي",
            subLinkItem: "/Riddence-waste-water",
          },

          {
            text: "معالجه الصرف الصحي الخام و أنواع محطات المعالجه",
            subLinkItem: "/kind-of-waste-water",
          },
          {
            text: "أهميه الحفاظ علي شبكه الصرف الصحي ",
            subLinkItem: "/save-web-waste-water",
          },
          {
            text: "دور إداره الصرف الصناعي",
            subLinkItem: "/waste-water-in-manufactring",
          },
          { text: " الصرف الصناعي", subLinkItem: "/manufactring-waste" },
        ],
      },
      {
        title: "التدريب",
        active: true,
        subMenuItem: [
          {
            text: "أنواع التدريب والقاعات",
            subLinkItem: "/general-admin-training",
          },
          /*{
            text: "تسجيل في المدرسه الفنيه",
            subLinkItem: "/register-in-school",
          },*/
          { text: "نتائج المدرسه ", subLinkItem: "/Result-of-school" },
        ],
      },
      {
        title: "وظائف",
        active: true,
        subMenuItem: [
          { text: "مسابقات و وظائف", subLinkItem: "/jobs-and-competition" },
          /* {
            text: " نتائج المسابقات بالرقم القومي ",
            subLinkItem: "/result-of-competition",
          },*/
          { text: "نتائج المسابقات", subLinkItem: "/result_of_worker" },
          /* {
            text: "مواعيد الإختبار التحريري للفنيين",
            subLinkItem: "/date_of_worker_exam",
          },*/
        ],
      },
      {
        title: "خدمات",
        active: true,
        subMenuItem: [
          // { text: "أرسل قراءه عدادك", subLinkItem: "/send-your-reader" },
          { text: " استعلم عن فاتورتك", subLinkItem: "/inquire-your-bill" },

          //   { text: "أدفع فاتورتك", subLinkItem: "/under-build" },
          //    { text: "أدخل قراءتك", subLinkItem: "/send-your-reader" },
          { text: " خدمه العملاء", subLinkItem: "/call-center" },
          { text: " ميثاق المتعاملين", subLinkItem: "/Customer-Charter" },
          { text: " دليل الخدمات", subLinkItem: "/Services-Evidance" },
          { text: "رحلة المتعامل للتعاقد على طلب خدمة ", subLinkItem: "/Contract-On-Service" },
          { text: "تقديم طلب", subLinkItem: "/provide-request" },
          { text: "تقديم شكوي", subLinkItem: "/provide-complaine" },
        ],
      },
      {
        title: "دعم النزاهة",
        subMenuItem: [
          {
            text: " نبذه عن إداره دعم النزاهة",
            subLinkItem: "/nabza-an-daam-elnazaha",
          },
          {
            text: " أبرز أعمال دعم النزاهة",
            subLinkItem: "/abrz-amaal-daam-elnazaha",
          },
          {
            text: "السلوك الوظيفي",
            subLinkItem: "/elslookElwazefy",
          },
        ],
      },
      {
        title: "معرض الصور ",
        active: true,
        subMenuItem: [
          { text: "جولات رئيس مجلس الإداره", subLinkItem: "/trips-the-boss" },
          { text: "معامل الشركه", subLinkItem: "/lab-of-company" },
          { text: " محطات الصرف", subLinkItem: "/waste-of-company" },
          { text: "مركز التدريب", subLinkItem: "/traning-of-company" },
          {
            text: " قطاع تكنولوجيا المعلومات",
            subLinkItem: "/information-technology-of-company",
          },
          { text: "المدرسه الفنيه ", subLinkItem: "/school-new-assuit" },
          { text: "النشاط الرياضي", subLinkItem: "/sport-of-company" },
        ],
      },
    ],

    items: [
      {
        src: require("@/assets/Images/3.png"),
        title: "  شركة مياه الشرب والصرف الصحي بأسيوط والوادي الجديد",
        subTitle:
          "ترحب بكم و نفخر بأننا احدي الشركات الرائده في جمهوريه مصر العربيه ",
        link: "/an-elsherka",
        details: "تعرف عل المزيد",
        animeClass: "tilt-in-fwd-tr",
      },
      {
        src: require("@/assets/Images/31.jpg"),
        title: "مركز خدمة العملاء",
        subTitle: " مراكز خدمه العملاء وطرق التواصل وعنوان أقرب فرع  ",
        link: "/call-center",
        details: "تعرف عل المزيد",
        animeClass: "tilt-in-fwd-tr",
      },
      {
        src: require("@/assets/Images/5.jpg"),
        title: "  الإداره العامه للتدريب",
        subTitle: "متاح حجز قاعات التدريب من داخل و خارج الشركة",
        link: "/general-admin-training",
        details: "تعرف عل المزيد",
        animeClass: "bounce-in-top",
      },
      {
        src: require("@/assets/Images/1.jpg"),
        title: "  معامل علي اعلي مستوي ",
        subTitle:
          "الاهتمام بمعايير منظمة الصحه العالمية للتاكد من جودة المياه بأحدث المعايير والتقنيات",
        link: "/lab-of-company-water",
        details: "تعرف عل المزيد",
        animeClass: "slide-in-elliptic-top-fwd",
      },
      {
        src: require("@/assets/Images/2.jpg"),
        /*   title: " انطلاق تطبيق قرائتي ",
        subTitle:
          " يعتمد تطبيق قرائتي التابع للشركه القابضه لمياه الشرب علي سهوله الاستخدام وسريه البيانات",*/
        link: "/readme",
        details: "لتنزيل برنامج قرائتي اضغط هنا",
        animeClass: "slide-in-elliptic-top-fwd",
      },
      {
        src: require("@/assets/Images/6.png"),
        /*  title: "  تطبيق الخط الساخن 125",
        subTitle:
          "يتيح تطبيق الخط الساخن التواصل مع شركه المياه اسيوط لحل المشكلات ",*/
        link: "/hotline125",
        details: "لتنزيل برنامج الخط الساخن أضغط هنا",
        animeClass: "roll-in-blurred-left",
      },
    ],

    cardServices: [
      {
        item: "تطبيق قرائتي ",
        color: "",
        link: "/readme",
        img: require("@/assets/Images/readme.jpg"),
        subText:
          "يتيح للمواطن تسجيل قراءة العداد بنفسه، وإضافة صورة العداد وهو ما يضمن دقة القراءة، على أن ترسل القراءات من يوم 1 إلى 15 من كل شهر وذلك لكل الشركات التابعة للشركة القابضة.",
      },
      {
        item: "تطبيق الخط الساخن ",
        color: "",
        link: "/hotline125",
        img: require("@/assets/Images/hotline.jpg"),
        subText:
          "يمكن من خلال التطبيق إرسال الشكاوي والمقترحات إلى الشركة كما يتيح ارسال إشعارات للمواطنين بالأعطال والكسور، وتنبيهات بمواعيد انقطاع المياه وأعمال الصيانة ودفع الفاتورة باستخدام التطبيق..",
      },
      {
        item: "إدخل قراءه عدادك  ",
        color: "",
        link: "/under-build",
        img: require("@/assets/Images/79.jpg"),
        subText:
          "تتيح الخدمة سداد فاتورة مياه الشرب والصرف الصحى الكترونيا بمحافظات (القاهرة، الجيزة، المنوفية، البحيرة، كفرالشيخ، سوهاج، الاسكندرية، البحر الاحمر، مطروح، سيناء)، وجارى تفعيلها بباقى المحافظات.",
      },
      {
        item: "استعلم عن فاتورتك",
        color: "",
        link: "/send-your-reader",
        img: require("@/assets/Images/54.png"),
        subText:
          "تتيح حساب قيمة الفاتورة الشهرية نظير كمية المياه المستهلكة، وفقا للتسعيرة المعتمدة من رئاسة مجلس الوزراء، تحقيقا لمبدأ الشفافية، وإمكانية ترشيد الاستهلاك.",
      },
    ],
    social_media: [
      {
        name: "facebook",
        link: "https://www.facebook.com/ASCWWeg",
        srcmedia: require("@/assets/facebook.svg"),
      },
      {
        name: "whatsapp",
        link:
          "//api.whatsapp.com/send?phone=01280733990&text=من فضلك أريد المساعدة- هل أنت متاح الأن ؟",
        srcmedia: require("@/assets/whatsapp.svg"),
      },
      {
        name: "youtube",
        link: "https://youtube.com/channel/UC73LZeR5Yr5TE7fsTzvZSVw",
        srcmedia: require("@/assets/youtube.svg"),
      },
    ],
    contact_traning: [
      {
        name: "facebook",
        link:
          "https://www.facebook.com/%D9%85%D8%B1%D9%83%D8%B2-%D8%A7%D9%84%D8%AA%D8%AF%D8%B1%D9%8A%D8%A8-%D8%A7%D9%84%D8%A7%D9%82%D9%84%D9%8A%D9%85%D9%8A-%D9%84%D8%B4%D8%B1%D9%83%D8%A9-%D9%85%D9%8A%D8%A7%D9%87-%D8%A7%D9%84%D8%B4%D8%B1%D8%A8-%D9%88%D8%A7%D9%84%D8%B5%D8%B1%D9%81-%D8%A7%D9%84%D8%B5%D8%AD%D9%8A-%D8%A8%D8%A7%D8%B3%D9%8A%D9%88%D8%B7-%D9%88%D8%A7%D9%84%D9%88%D8%A7%D8%AF%D9%8A-%D8%A7%D9%84%D8%AC%D8%AF%D9%8A%D8%AF-118418282895913",
        srcmedia: require("@/assets/facebook.svg"),
      },
      {
        name: "01280733381",
        srcmedia: require("@/assets/phone.svg"),
        fun: true,
      },
      {
        name: "youtube",
        link: "https://youtube.com/channel/UC73LZeR5Yr5TE7fsTzvZSVw",
        srcmedia: require("@/assets/youtube.svg"),
      },
    ],
    branchServices: [
      {
        Id: 1,
        name: "فرع ديروط",
        lat: 27.445213637942377,
        lng: 30.821815489409442,
        address: "شارع البحر الاعظم ",
        phone: "01210487781",
        fax: "088-2131662",
      },
      {
        Id: 2,
        name: "فرع منفلوط",
        lat: 27.311585834234137,
        lng: 30.971398428679304,
        address: "شارع جسر ابومنديل - بجوار المعهد الأزهري ",
        phone: "01210487749",
        fax: "088-2131662",
      },
      {
        Id: 3,
        name: "فرع القوصيه",
        lat: 27.43971362706084,
        lng: 30.82680233566733,
        address: "امام المرور القديم",
        phone: "01210487678",
        fax: "088-2131662",
      },
      {
        Id: 4,
        name: " خدمة العملاء الرئيسي",
        lat: 27.173048030143857,
        lng: 31.203093855181375,
        address: "أسيوط-امتداد شارع الجيش امام مول النصر",
        phone: "01278648276",
        fax: "088-2131662",
      },

      {
        Id: 7,
        name: "فرع أبنوب  ",
        lat: 27.26748453868315,
        lng: 31.151932937089843,
        address: "شارع عثمان الغزالي بجوار المخبز الاَلي",
        phone: "01210487737",
        fax: "088-2131662",
      },
      {
        Id: 8,
        name: "فرع مركز الفتح ",
        lat: 27.175626839427586,
        lng: 31.183935995653986,
        address: "الناصريه أمام مركز الشرطة",
        phone: "01210487740",
        fax: "088-2131662",
      },
      {
        Id: 9,
        name: "فرع مركز البداري ",
        lat: 26.9924993574445,
        lng: 31.410070861364876,
        address: "شارع مجلس النيابه القديم",
        phone: "01284447689",
        fax: "088-2131662",
      },
      {
        Id: 10,
        name: "فرع الغنايم ",
        lat: 26.995696801098525,
        lng: 31.254902339832437,
        address: "الغنايم بحري خلف مركز الشرطة  ",
        phone: "01210487696",
        fax: "088-2131662",
      },
      {
        Id: 11,
        name: "فرع أبوتيج ",
        lat: 27.051081544529556,
        lng: 31.319819026342646,
        address: "بجوار رعاية الطفل",
        phone: "01210487708",
        fax: "088-2131662",
      },
      {
        Id: 12,
        name: "فرع صدفه ",
        lat: 26.949598924427598,
        lng: 31.365471048103668,
        address: "شارع الصيانة",
        phone: "01210487698",
        fax: "088-2131662",
      },
      {
        Id: 12,
        name: "فرع ساحل سليم",
        lat: 26.949598924427598,
        lng: 31.365471048103668,
        address: "شارع الجمهوريه خلف فندق الوطنيه ",
        phone: "01210487718",
        fax: "088-2131662",
      },
    ],
    locations: [
      {
        Id: 1,
        name: "فرع ديروط",
        lat: 27.445213637942377,
        lng: 30.821815489409442,
        address: "شارع البحر الاعظم ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 2,
        name: "فرع منفلوط",
        lat: 27.311585834234137,
        lng: 30.971398428679304,
        address: "شارع جسر ابومنديل - بجوار المعهد الديني ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 3,
        name: "فرع القوصيه",
        lat: 27.43971362706084,
        lng: 30.82680233566733,
        address: "منطقة حسيب - امام المرور القديم",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 4,
        name: "الفرع الرئيسي",
        lat: 27.173048030143857,
        lng: 31.203093855181375,
        address: "محطه مياه المرشحه بنزله عباللاه- اسيوط",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 5,
        name: "فرع شرق اسيوط ",
        lat: 27.18512873828474,
        lng: 31.194855097768585,
        address: "شارع الهلالي امام مديرية الاسكان ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 6,
        name: "فرع غرب أسيوط ",
        lat: 27.17557501593394,
        lng: 31.17646148223588,
        address: "شارع 23 يوليو خلف بنك ناصر ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 7,
        name: "فرع أبنوب  ",
        lat: 27.26748453868315,
        lng: 31.151932937089843,
        address: "شارع الصيانة بجوار المخبز الالى  ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 8,
        name: "فرع مركز الفتح ",
        lat: 27.175626839427586,
        lng: 31.183935995653986,
        address: "الناصريه أمام مركز الشرطة",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 9,
        name: "فرع مركز البداري ",
        lat: 26.9924993574445,
        lng: 31.410070861364876,
        address: "شارع جمال عبدالناصر امام السجل المدنى",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 10,
        name: "فرع الغنايم ",
        lat: 26.995696801098525,
        lng: 31.254902339832437,
        address: "محطه مياه شرق السوهاجيه  ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 11,
        name: "فرع أبوتيج ",
        lat: 27.051081544529556,
        lng: 31.319819026342646,
        address: "شارع المحطه البحريه امام المحكمة",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 12,
        name: "فرع صدفه ",
        lat: 26.949598924427598,
        lng: 31.365471048103668,
        address: "شارع المحطه البحريه امام الصيانة",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
      {
        Id: 12,
        name: "فرع مركزأسيوط ",
        lat: 26.949598924427598,
        lng: 31.365471048103668,
        address: "شارع الجمهوريه خلف فندق الوطنيه ",
        phone: "088/2131662 - 2131654- 2131655",
        fax: "088-2131662",
      },
    ],
    tendersArray: [
      { id: 1, namePdf: "achivement.pdf" },
      { id: 2, namePdf: "people_assistant.pdf" },
      { id: 3, namePdf: "demo.pdf" },
      { id: 4, namePdf: "achivement.pdf" },
      { id: 5, namePdf: "people_assistant.pdf" },
      { id: 6, namePdf: "demo.pdf" },
      { id: 7, namePdf: "achivement.pdf" },
    ],
    departmentTraning: [
      {
        id: 1,
        link: "/Traning-management/1",
        img: require("@/assets/Images/58.jpg"),
        title: "  علاقات عامة",
        arrayOfTraning: [
          "PHOTOSHOP ",
          "التسويق الرقمى",
          "بحوث الراى",
          "العلاقات العامة من منظور جديد",
          "ادارة المراسم والبروتوكولات وتنظيم المعارض والمؤتمرات",
          "كتابة وصياغة الاخبار الصحفية والتقارير الصحفية",
        ],
      },
      {
        id: 2,
        link: "/Traning-management/2",
        img: require("@/assets/Images/57.jpg"),
        title: "برامج تكنولوجيا المعلومات ",
        arrayOfTraning: ["CCNA Certification", "icdl"],
      },
      {
        id: 3,
        link: "/Traning-management/3",
        img: require("@/assets/Images/56.jpeg"),
        title: " ادارة التوعية -المشاركه الاجتماعيه ",
        arrayOfTraning: [
          "كيفية اعداد البحوث الكمية والنوعية واعداد المسوح المجتمعية الميداني",
          "مهارات الارشاد والتوعية",
          "الاتصال والتواصل المجتمعى والمشاركة المجتمعية",
        ],
      },
      {
        id: 4,
        link: "/Traning-management/4",
        img: require("@/assets/Images/59.jpg"),
        title: "إدارى",
        arrayOfTraning: [
          "fidic",
          "مسار وظيفى للدرجات القيادية",
          "ادارة الازمات",
          "ادارة الوقت",
          "الاتجاهات الحديثة فى الادارة",
          "طرق الوقاية من مخاطر بيئة العمل وانواع هذه المخاطر",
          "المهارات المتقدمة فى اعداد التقارير والخطابات والمذكرات",
          "مهارات وضع معايير التقييم ومؤشرات الاداء",
          "TOT",
          "اعداد كوادر لصف  ثانى",
          "لغة انجليزية",
        ],
      },
      {
        id: 5,
        link: "/Traning-management/5",
        img: require("@/assets/Images/60.jpg"),
        title: "موارد بشرية",
        arrayOfTraning: [
          "احكام قانون كسب العمل وتعديلاتة",
          "تقييم الأداء",
          "اعداد الهياكل التنظيمية تحليل الوظائف",
          "التسويات ",
          "انشاء وثائق الخدمة ",
          "اجراءات العمل بالموارد البشرية ",
          "اجراءات العمل بالموارد البشرية ",
          "استراتيجة الموارد البشرية",
          "دليل لائحة واجراءات",
        ],
      },
      {
        id: 6,
        link: "/Traning-management/6",
        img: require("@/assets/Images/61.png"),
        title: "اخصائى تدريب",
        arrayOfTraning: [
          " قياس مردود التدريب والعائد على الاستثمار",
          "برنامج المحاسب المحترف الشامل",
          "التحويل الرقمى وكيفية تطبيق من خلال البرامج",
          "مهارات اعداد المواد التفاعلية للتدريب الالكتروني",
          "التسويق الالكتروني",
        ],
      },

      {
        id: 7,
        link: "/Traning-management/7",
        img: require("@/assets/Images/62.png"),
        title: "أخصائي أمن",
        arrayOfTraning: ["كتابة التقارير الامني", "تأمين المنشأت"],
      },
      {
        id: 8,
        link: "/Traning-management/8",
        img: require("@/assets/Images/63.jpg"),
        title: "سكرتارية",
        arrayOfTraning: [" الارشفة وحفظ الملفات", "مهارات السكرتارية"],
      },
      {
        id: 9,
        link: "/Traning-management/9",
        img: require("@/assets/Images/64.png"),
        title: "فنى حملة",
        arrayOfTraning: [
          "دورة الزيت",
          "الدوائر الاساسية فى المركبات",
          "انواع المركبات و مكوناتها والاعطال الشائعة",
          "انواع المركبات ومكوناتها والاعطال الشائعة",
        ],
      },
    ],
    allTenders: [],
    newsArrayStore: [],
    newArraySearches: [],
    homeNewsArray: [],
    homeProjectsArray: [],
    adminInfo: [],
    adminBoardmembers: [],
    adminStrucher: [],
  },
  /*plugins: [createPersistedState({
    key:"someKey",
    storage: window.localStorage
  })],*/
  mutations: {
    getArrItem: (state) => (id) => {
      return state.items.find((item) => item.id === id);
    },
    fetchNews: (state, selectedNews) => {
      state.newsArray = selectedNews;
    },
    returnLang: (state) => {
      return state.lang;
    },
    /* increment: state => state.count++,
     decrement: state => state.count--,*/
    changechange: (state) => (state.lang = !state.lang),

    setNewArrayfunction: (state, setArray) => {
      state.newArraySearches = setArray;
    },
    returnArraySearches: ({ state }) => {
      return state.newArraySearches;
    },
  },
  actions: {
    async findSelectedNewsHome({ state }) {
      // axios.get("http://localhost:8080/api/apiGetAllNews/")
      // axios.get(`https://new.ascww.org/api/apiGetAllNews`)

      axios
        .get("https://backend.ascww.org/api/news/home")
        .then((response) => {
          state.homeNewsArray = response.data;
          return state.homeNewsArray;
        })
        .catch((error) => {
          console.log(error.statusText);
          return 0;
        });
      return 1;
    },
    async findSelectedItem({ state }) {
      // axios.get("http://localhost:8080/api/apiGetAllNews/")
      // axios.get(`https://new.ascww.org/api/apiGetAllNews`)

      axios
        .get("https://backend.ascww.org/api/news")
        .then((response) => {
          state.newsArrayStore = response.data;
          return state.newsArrayStore;
        })
        .catch((error) => {
          console.log(error.statusText);
          return 0;
        });
      return 1;
    },
    async findAllProjects({ state }) {
      axios
        .get("https://backend.ascww.org/api/projects")
        .then((response) => {
          state.projects = response.data;
          return state.projects;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findHomeProjects({ state }) {
      axios
        .get("https://backend.ascww.org/api/projects/home")
        .then((response) => {
          state.homeProjectsArray = response.data;
          return state.homeProjectsArray;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllTenders({ state }) {
      axios
        .get("https://backend.ascww.org/api/tenders")
        .then((response) => {
          state.allTenders = response.data;

          return state.allTenders;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllCareers({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers")
        .then((response) => {
          state.careers = response.data;

          return state.careers;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    ///////////////ca/////////////////////////
    async findAllCa({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/ca")
        .then((response) => {
          state.ca = response.data;

          return state.ca;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    ////////////pcr///////////////
    async findAllPcr({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/pcr")
        .then((response) => {
          state.pcr = response.data;

          return state.pcr;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllGer({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/ger")
        .then((response) => {
          state.ger = response.data;

          return state.ger;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllWed({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/wed")
        .then((response) => {
          state.wed = response.data;

          return state.wed;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllWtr({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/wtr")
        .then((response) => {
          state.wtr = response.data;

          return state.wtr;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllPed({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/ped")
        .then((response) => {
          state.ped = response.data;

          return state.ped;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllPtr({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/ptr")
        .then((response) => {
          state.ptr = response.data;

          return state.ptr;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllId({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/id")
        .then((response) => {
          state.idcar = response.data;

          return state.idcar;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async findAllAn({ state }) {
      axios
        .get("https://backend.ascww.org/api/careers/an")
        .then((response) => {
          state.an = response.data;

          return state.an;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },

    async adminInfoFun({ state }) {
      axios
        .get("https://backend.ascww.org/api/admin-info")
        .then((response) => {
          state.adminInfo = response.data;

          return state.adminInfo;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    async adminBoardMemberFun({ state }) {
      axios
        .get("https://backend.ascww.org/api/admin-board-members")
        .then((response) => {
          state.adminBoardmembers = response.data;

          return state.adminBoardmembers;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
  },
});
